import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import Image from 'next/image';
import dynamic from 'next/dynamic'
import {
  ShoppingCartIcon,
  UserCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  ChevronDownIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import '@algolia/autocomplete-theme-classic';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link'
import AutocompleteWithPreview from '../../components/Algolia/SearchFile';
import { useGetCartMutation } from '../Store/Features/CartAPI';
import { addItemToCart } from '../Store/reducers/cartSlice';
import { usePostApiMutation } from '../Store/Features/PostApiRequest';
import { encode } from '../../utils/encrypt';
import { useRouter } from 'next/router';
import { UserAtom } from '../Store/States/state';
import { useAtom } from 'jotai';




const MobileCategories = {
  categories: [
    {
      id: 'Consulting',
      name: 'Consulting',
      sections: [
        {
          items: [
            {
              name: 'Consulting',
              link: '/consulting'
            },
            {
              name: 'Consulting Services',
              link: '/consulting-services'
            },
            {
              name: 'Asset Prioritization',
              link: '/consulting/asset-prioritizaton-services'
            },
            {
              name: 'Business Development',
              link: '/consulting/business-development-services'
            },
            {
              name: 'Licensing Services',
              link: '/consulting/licensing-services'
            },
            {
              name: 'Merger and Acquisitions',
              link: '/consulting/merger-and-acquisition'
            },
            {
              name: 'Partner Identification',
              link: '/consulting/partner-identification-services'
            },
            {
              name: 'Competitive Benchmarking',
              link: '/consulting/competitive-benchmarking-services'
            },
            {
              name: 'Portfolio Management',
              link: '/consulting/pharmaceutical-portfolio-management-solutions'
            },
            {
              name: 'Pipeline Assessment',
              link: '/consulting/pipeline-assessment-services'
            },
            {
              name: 'Market Assessment',
              link: '/consulting/market-assessment-services'
            },
            {
              name: 'Competitive Intelligence',
              link: '/consulting/competitive-intelligence-services'
            },
            {
              name: 'R&D Analysis',
              link: '/consulting/r-and-d-analysis'
            },
            {
              name: 'Regulatory Analysis',
              link: '/consulting/regulatory-analysis-services'
            },
            {
              name: 'Primary Research',
              link: '/consulting/primary-research-services'
            },
            {
              name: 'Conference Coverage',
              link: '/consulting/conference-coverage-services'
            },
            {
              name: 'CI Tracking',
              link: '/consulting/ci-tracking'
            },
            {
              name: "Market & Competitive Assessment",
              link: "/case-study/market-&-competitive-assessment",
            },
            {
              name: "Market Size & Forecast Assessment",
              link: "/case-study/market-size-&-forecast-assessment",
            },
          ],
        },
      ],
    },
    {
      id: "Capabilities",
      name: "Capabilities",
      sections: [
        {
          items: [
            {
              name: "Medical Devices",
              link: "/medical-devices",
            },
            {
              name: "Market Research",
              link: "/market-research",
            },
            {
              name: "Subscription",
              link: "/pharmdelve",
            },
            {
              name: "Competitive Intelligence",
              link: "/consulting/competitive-intelligence-services",
            },
            {
              name: "Business Consulting",
              link: "/consulting",
            },
            {
              name: "Conference Coverage Solutions",
              link: "/consulting/conference-coverage-services",
            },
          ],
        },
      ],
    },
    {
      id: "Insights",
      name: "Resources",
      sections: [
        {
          items: [

            {
              name: "Blog",
              link: "/blog/",
            },
            {
              name: "Newsletter/Whitepaper",
              link: "/whitepaper-newsletter",
            },
            {
              name: "Infographics",
              link: "/infographics",
            },
            {
              name: "Case Study",
              link: "/case-study",
            },
            {
              name: "Events/Conference",
              link: "/events",
            },
            {
              name: "Press Release",
              link: "/press-release",
            },
          ],
        },
      ],
    },
    {
      id: 'TherapyArea',
      name: 'Therapy Area',
      sections: [
        {
          items: [
            {
              name: "Rare Diseases",
              link: "/rare-diseases",
            },
            {
              name: "Oncology",
              link: "/oncology",
            },
            {
              name: "Respiratory Diseases",
              link: "/report-store.php?therapeutic_areas=Respiratory Diseases",
            },
            {
              name: "Dermatology",
              link: "/report-store.php?therapeutic_areas=Dermatology",
            },
            {
              name: "Neurology",
              link: "/report-store.php?therapeutic_areas=Neurology",
            },
            {
              name: "Respiratory Diseases",
              link: "/report-store.php?therapeutic_areas=Respiratory Diseases",
            },
            {
              name: "Dermatology",
              link: "/report-store.php?therapeutic_areas=Dermatology",
            },
            {
              name: "Neurology",
              link: "/report-store.php?therapeutic_areas=Neurology",
            },
            {
              name: "Cardiovascular Diseases",
              link: "/report-store.php?therapeutic_areas=Cardiovascular diseases",
            },
            {
              name: "Cell and Gene Therapy",
              link: "/blog/cell-and-gene-therapy",
            },
            {
              name: "Central Nervous System",
              link: "/report-store.php?therapeutic_areas=Central Nervous System",
            },
            {
              name: "Dental and Oral Health",
              link: "/report-store.php?therapeutic_areas=Dental and Oral Health",
            },
            {
              name: "Gastroenterology",
              link: "/report-store.php?therapeutic_areas=Gastroenterology",
            },
            {
              name: "Genetic Disorders",
              link: "/report-store.php?therapeutic_areas=Genetic Disorders",
            },
            {
              name: "Otolaryngology",
              link: "/report-store.php?therapeutic_areas=Otolaryngology",
            },
            {
              name: "Non-Malignant Disorders",
              link: "/report-store.php?therapeutic_areas=Non-Malignant Disorders",
            },
            {
              name: "Hematological Disorders",
              link: "/report-store.php?therapeutic_areas=Hematological Disorders",
            },
            {
              name: "Infectious Diseases",
              link: "/report-store.php?therapeutic_areas=Infectious Diseases",
            },
            {
              name: "Musculoskeletal",
              link: "/report-store.php?therapeutic_areas=Musculoskeletal",
            },
            {
              name: "Ophthalmology",
              link: "/report-store.php?therapeutic_areas=Ophthalmology",
            },
            {
              name: "Male Health",
              link: "/report-store.php?therapeutic_areas=Male Health",
            },
            {
              name: "Other Diseases",
              link: "/report-store.php?therapeutic_areas=Other Diseases",
            },
            {
              name: "Genito Urinary System and Sex Hormones",
              link: "/report-store.php?therapeutic_areas=Genito Urinary System and Sex Hormones",
            },
            {
              name: "Endocrinology and Metabolic Disorders",
              link: "/report-store.php?therapeutic_areas=Endocrinology and Metabolic Disorders",
            },
            {
              name: "Immunological and Autoimmune Disorders",
              link: "/report-store.php?therapeutic_areas=Immunological and Autoimmune Disorders",
            },
          ],
        },
      ],
    },
  ],
  pages: [
    {
      name: 'About Us',
      link: '/about-us'
    },
    {
      name: 'Privacy Policy',
      link: '/privacy-policy'
    },
    {
      name: 'Terms & Conditions',
      link: '/terms-and-conditions'
    },
    {
      name: 'Refund Policy',
      link: '/refund-policy'
    },
    {
      name: 'Cancellation Policy',
      link: '/cancellation-policy'
    },
    {
      name: 'Contact Us',
      link: '/contact-us'
    },
  ],
}
const Categories = {
  AboutUs: [
    {
      id: 'AboutUs',
      name: 'About Us',
      sections: [
        {
          items: [
            {
              name: "About DelveInsight",
              link: "/about-us",
              icon: ChartBarIcon,
            },
            {
              name: "Careers",
              link: "/careers",
              icon: CursorClickIcon,
            },
            {
              name: "Privacy Policy",
              link: "/privacy-policy",
              icon: ShieldCheckIcon,
            },
            {
              name: "Terms & Conditions",
              link: "/terms-and-conditions",
              icon: ViewGridIcon,
            },
            {
              name: "Refund Policy",
              link: "/refund-policy",
              icon: RefreshIcon,
            },
            {
              name: 'Cancellation Policy',
              link: '/cancellation-policy',
              icon: LockClosedIcon,
            },
            {
              name: "Contact Us",
              link: "/contact-us",
              icon: ChartBarIcon,
            },
          ],
        },
      ],
    },
  ],
  Consulting: [
    {
      id: 'Consulting',
      name: 'Consulting',
      featured: [
        {
          name: "Market & Competitive Assessment",
          link: "/case-study/market-&-competitive-assessment",
          imageSrc: "/mainAssets/assets/images/navbar/case-study-1.png",
          shortDescription: "Creating Competitive Advantage Through Precise Market Analysis",
        },
        {
          name: "Market Size & Forecast Assessment",
          link: "/case-study/market-size-&-forecast-assessment",
          imageSrc: "/mainAssets/assets/images/navbar/case-study-2.png",
          shortDescription: "Analyzing Market Potential to Implement Market Entry Strategies",
        },
      ],
      sections: [
        {
          items: [
            {
              name: 'Asset Prioritization',
              link: '/consulting/asset-prioritizaton-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/asset-prioritize-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/asset-prioritize-white-01.svg",
              altText: "Asset Prioritization"
            },
            {
              name: 'Business Development',
              link: '/consulting/business-development-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/business-development-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/business-development-white-01.svg",
              altText: "Business Development"
            },
            {
              name: 'Licensing Services',
              link: '/consulting/licensing-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/licensing-services-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/licensing-services-white-01.svg",
              altText: "Licensing Services"
            },
            {
              name: 'Merger and Acquisitions',
              link: '/consulting/merger-and-acquisition',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/merger-&-acquisitions-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/merger-&-acquisitions-white-01.svg",
              altText: "Merger and Acquisitions"
            },
            {
              name: 'Partner Identification',
              link: '/consulting/partner-identification-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/partner-identification-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/partner-identification-white-01.svg",
              altText: "Partner Identification"
            },
            {
              name: 'Portfolio Management',
              link: '/consulting/pharmaceutical-portfolio-management-solutions',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/portfolio-management-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/portfolio-management-white-01.svg",
              altText: "Portfolio Management"
            },
            {
              name: 'Pipeline Assessment',
              link: '/consulting/pipeline-assessment-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/pipeline-assessment-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/pipeline-assessment-white-01.svg",
              altText: "Pipeline Assessment"
            },
          ],
        },
        {
          items: [
            {
              name: 'Market Assessment',
              link: '/consulting/market-assessment-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/market-assessment-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/market-assessment-white-01.svg",
              altText: "Market Assessment"
            },
            {
              name: 'Competitive Intelligence',
              link: '/consulting/competitive-intelligence-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/competitive-intelligence-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/competitive-intelligence-white-01.svg",
              altText: "Competitive Intelligence"
            },
            {
              name: 'R&D Analysis',
              link: '/consulting/r-and-d-analysis',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/r-&-d-analysis-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/r-&-d-analysis-white-01.svg",
              altText: "R&D Analysis"
            },
            {
              name: 'Regulatory Analysis',
              link: '/consulting/regulatory-analysis-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/regulatory-analysis-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/regulatory-analysis-white-01.svg",
              altText: "Regulatory Analysis"
            },
            {
              name: 'Primary Research',
              link: '/consulting/primary-research-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/primary-research-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/primary-research-white-01.svg",
              altText: "Primary Research"
            },
            {
              name: 'Conference Coverage',
              link: '/consulting/conference-coverage-services',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/conference-coverage-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/conference-coverage-white-01.svg",
              altText: "Conference Coverage"
            },
            {
              name: 'CI Tracking',
              link: '/consulting/ci-tracking',
              imageIconBlue: "/mainAssets/assets/images/icons/svg-icons/header-icons/asset-prioritize-01.svg",
              imageIconWhite: "/mainAssets/assets/images/icons/svg-icons/header-icons/asset-prioritize-white-01.svg",
              altText: "CI Tracking"
            },
          ],
        },
      ],
    },
  ],
  Capabilities: [
    {
      id: "Capabilities",
      name: "Capabilities",
      sections: [
        {
          items: [
            {
              name: "Medical Devices",
              link: "/medical-devices",
              imageSrc: "/mainAssets/assets/images/navbar/medical-devices.png",
              altText: "Medical Devices",
              shortDescription: "Decipher the future insights and trends of the MedTech market",
            },
            {
              name: "Market Research",
              link: "/market-research",
              imageSrc: "/mainAssets/assets/images/navbar/market-research.png",
              altText: "Market Research",
              shortDescription: "Extensive market analysis covering strategies and manifesting growth opportunities",
            },
            {
              name: "Subscription",
              link: "/pharmdelve",
              imageSrc: "/mainAssets/assets/images/navbar/subscription.png",
              altText: "Subscription",
              shortDescription: "Subscription-based platform for all the latest updates on market trends and novel developments",
            },
          ],
        },
        {
          items: [
            {
              name: "Competitive Intelligence",
              link: "/consulting/competitive-intelligence-services",
              imageSrc: "/mainAssets/assets/images/navbar/competitive-intelligence.png",
              altText: "Competitive Intelligence",
              shortDescription: "Research-based solutions to outlast the competition",
            },
            {
              name: "Business Consulting",
              link: "/consulting",
              imageSrc: "/mainAssets/assets/images/navbar/business-consulting.png",
              altText: "Business Consulting",
              shortDescription: "Reliable business consulting services to excel in the market",
            },
          ],
        },
      ],
      featured: [
        {
          name: "Conference Coverage Solutions",
          shortDescription: "Deep dive analysis of all the intelligence captured at the conference",
          link: "/consulting/conference-coverage-services",
          imageSrc: "/mainAssets/assets/images/navbar/customized-solutions.png",
          altText: "Conference Coverage Solutions",
        },
      ],
    },
  ],
  Insights: [
    {
      id: "Insights",
      name: "Resources",
      featured: [
        {
          name: "Blog",
          shortDescription: "Take a sneak peek into the latest developments in therapeutic space",
          link: "/blog/",
          imageSrc: "/mainAssets/assets/images/navbar/blog.png",
          altText: "Blog",
        },
        {
          name: "Newsletter/Whitepaper",
          shortDescription: "Receive regular healthcare updates and alerts via email",
          link: "/whitepaper-newsletter",
          imageSrc: "/mainAssets/assets/images/navbar/newsletter-whitepaper.png",
          altText: "Newsletter/Whitepaper",
        },
        {
          name: "Infographics",
          shortDescription: "Stay updated with the evolving market trends and news",
          link: "/infographics",
          imageSrc: "/mainAssets/assets/images/navbar/infographics.png",
          altText: "Infographics",
        },
      ],
      sections: [
        {
          items: [

            {
              name: "Case Study",
              link: "/case-study",
              imageSrc: "/mainAssets/assets/images/navbar/case-study.png",
              altText: "Case Study",
              shortDescription: "Collection of success stories to understand the market scenario"
            },
            {
              name: "Events/Conference",
              link: "/events",
              imageSrc: "/mainAssets/assets/images/navbar/events.png",
              altText: "Events/Conference",
              shortDescription: "Keep an eye on global healthcare conferences and events"
            },
            {
              name: "Press Release",
              link: "/press-release",
              imageSrc: "/mainAssets/assets/images/navbar/press-release.png",
              altText: "Press Release",
              shortDescription: "Track all the recent happenings in the pharma market"
            },
          ],
        },
      ],
    },
  ],
  TherapyArea: [
    {
      id: 'TherapyArea',
      name: 'Therapy Area',
      sections: [
        {
          items: [
            {
              name: "Cardiovascular Diseases",
              link: "/report-store.php?therapeutic_areas=Cardiovascular diseases",
            },
            {
              name: "Cell and Gene Therapy",
              link: "/blog/cell-and-gene-therapy",
            },
            {
              name: "Central Nervous System",
              link: "/report-store.php?therapeutic_areas=Central Nervous System",
            },
            {
              name: "Dental and Oral Health",
              link: "/report-store.php?therapeutic_areas=Dental and Oral Health",
            },
            {
              name: "Gastroenterology",
              link: "/report-store.php?therapeutic_areas=Gastroenterology",
            },
            {
              name: "Genetic Disorders",
              link: "/report-store.php?therapeutic_areas=Genetic Disorders",
            },
            {
              name: "Otolaryngology",
              link: "/report-store.php?therapeutic_areas=Otolaryngology",
            },
          ],
        },
        {
          items: [
            {
              name: "Non-Malignant Disorders",
              link: "/report-store.php?therapeutic_areas=Non-Malignant Disorders",
            },
            {
              name: "Hematological Disorders",
              link: "/report-store.php?therapeutic_areas=Hematological Disorders",
            },
            {
              name: "Infectious Diseases",
              link: "/report-store.php?therapeutic_areas=Infectious Diseases",
            },
            {
              name: "Musculoskeletal",
              link: "/report-store.php?therapeutic_areas=Musculoskeletal",
            },
            {
              name: "Ophthalmology",
              link: "/report-store.php?therapeutic_areas=Ophthalmology",
            },
            {
              name: "Male Health",
              link: "/report-store.php?therapeutic_areas=Male Health",
            },
            {
              name: "Other Diseases",
              link: "/report-store.php?therapeutic_areas=Other Diseases",
            },
          ],
        },
        {
          items: [
            {
              name: "Genito Urinary System and Sex Hormones",
              link: "/report-store.php?therapeutic_areas=Genito Urinary System and Sex Hormones",
            },
            {
              name: "Endocrinology and Metabolic Disorders",
              link: "/report-store.php?therapeutic_areas=Endocrinology and Metabolic Disorders",
            },
            {
              name: "Immunological and Autoimmune Disorders",
              link: "/report-store.php?therapeutic_areas=Immunological and Autoimmune Disorders",
            },
          ],
        },
      ],
      featured: [
        {
          name: "Rare Diseases",
          link: "/rare-diseases",
          imageSrc: "/mainAssets/assets/images/navbar/dummy.png",
          altText: "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Oncology",
          link: "/oncology",
          imageSrc: "/mainAssets/assets/images/navbar/dummy.png",
          altText: "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Respiratory Diseases",
          link: "/report-store.php?therapeutic_areas=Respiratory Diseases",
          imageSrc: "/mainAssets/assets/images/navbar/dummy.png",
          altText: "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Dermatology",
          link: "/report-store.php?therapeutic_areas=Dermatology",
          imageSrc: "/mainAssets/assets/images/navbar/dummy.png",
          altText: "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Neurology",
          link: "/report-store.php?therapeutic_areas=Neurology",
          imageSrc: "/mainAssets/assets/images/navbar/dummy.png",
          altText: "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
      ],
    },

  ],
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Header({ metaDetails, slug }) {

  const [user, setUser] = useAtom(UserAtom)
  const [searchIcon, setSearchIcon] =useState(false)
  const [open, setOpen] = useState(false)
  const [auth, setauth] = useState(false)
  // const [user, setUser] = useState(false)
  const [cart, setCart] = useState([])
  const cartValue = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const [currentUrl, setCurrentUrl] = useState("")

  let router = useRouter()

  const [getCart] = useGetCartMutation();
  const [PostApiRequest] = usePostApiMutation();



  useEffect(() => {
    setSearchIcon(true)
    if (typeof window !== 'undefined' && window.localStorage) {
      getCart({
        "tmp_id": localStorage.getItem('access_token') || "no-token",
        "user_id": ""
      }).then((res) => {
        dispatch(addItemToCart(res.data?.data));
        setCart(res.data?.data)
      })

      PostApiRequest({
        data: { token: localStorage.getItem('auth_user_token') || 'No Token' },
        url: '/api/user/login'
      }).then(async (resp) => {
        if (resp?.data?.code == 200) {
          if (process.env.ENV === 'local') {
            
            localStorage.setItem('auth_data', JSON.stringify(resp?.data?.data))
          } else {
           
            localStorage.setItem('auth_data', encode(resp?.data?.data))
          }

          setUser(resp?.data?.data)
          setauth(true)
        } else {
          setUser(null)
          localStorage.removeItem('auth_data')
          localStorage.removeItem('auth_user_token')
        }
      })
    }
  }, [])

  useEffect(() => {
    let url = window.location.href;
    if (url != currentUrl) {
      setCurrentUrl(url)
      setOpen(false);
    }
  }, [router.query.slug])

  const [isHoveredAbout, setIsHoveredAbout] = useState(false);
  const [isHoveredConsulting, setIsHoveredConsulting] = useState(false);
  const [isHoveredCapabilities, setIsHoveredCapabilities] = useState(false);
  const [isHoveredResource, setIsHoveredResource] = useState(false);
  const [isHoveredTherapyAera, setIsHoveredTherapyAera] = useState(false);
  return (
    <div className="fixed inset-x-0 top-0 z-50 text-black dark:bg-gray-800 dark:text-white focus-visible:outline-none">
      {/* Mobile menu */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 flex lg:hidden" onClose={setOpen}>
          <Transition.Child
            key="transition-1"
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            key="transition-2"
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col w-full max-w-sm pb-12 overflow-y-auto text-black bg-white shadow-xl md:max-w-lg dark:bg-gray-800 dark:text-white">
              <div className="flex items-center justify-end w-full">
                <button
                  type="button"
                  className="flex items-center justify-end p-1 text-blue-600 rounded"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="flex justify-end w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="flex px-4 space-x-3">
                    {Categories.Consulting.map((category, i) => (
                      <Tab
                        key={category.name + '-tab-' + i}
                        className={({ selected }) =>
                          classNames(
                            selected ? 'text-sm text-[#1b5df3] border-[#1b5df3]' : 'text-sm text-gray-900 border-transparent',
                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base'
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                    {Categories.Capabilities.map((category, i) => (
                      <Tab
                        key={category.name + '-tab-' + i}
                        className={({ selected }) =>
                          classNames(
                            selected ? 'text-sm text-[#1b5df3] border-[#1b5df3]' : 'text-sm text-gray-900 border-transparent',
                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base'
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                    {Categories.Insights.map((category, i) => (
                      <Tab
                        key={category.name + '-tab-' + i}
                        className={({ selected }) =>
                          classNames(
                            selected ? 'text-sm text-[#1b5df3] border-[#1b5df3]' : 'text-sm text-gray-900 border-transparent',
                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base'
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                    {Categories.TherapyArea.map((category, i) => (
                      <Tab
                        key={category.name + '-tab-' + i}
                        className={({ selected }) =>
                          classNames(
                            selected ? 'text-sm text-[#1b5df3] border-[#1b5df3]' : 'text-sm text-gray-900 border-transparent',
                            'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base'
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels as={Fragment}>
                  {MobileCategories.categories.map((category, i) => (
                    <Tab.Panel key={category.name + '-panel-' + i} className="px-4 pb-6 space-y-3 lg:pt-10" >
                      {category.sections.map((section) => (
                        <div key={section.name + '-' + i}>
                          <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                            {section.name}
                          </p>
                          <ul role="list"
                            className="flex flex-col mt-3 space-y-3">
                            {section.items.map((item, i) => (
                              <li key={item.name + '-panelSec-' + i} className="flow-root">
                                {
                                  (item.name == 'Blog')
                                    ?
                                    <a href={process.env.APP_URL + item.link} className="block p-2 -m-2 text-sm text-gray-900">
                                      {item.name}
                                    </a>
                                    :
                                    <Link href={process.env.APP_URL + item.link} className="block p-2 -m-2 text-sm text-gray-900">
                                      {item.name}
                                    </Link>
                                }
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </Tab.Panel>
                  ))}
                  <div className="border-t">
                    <ul role="list" className="flex flex-col px-4 mt-3 space-y-3 hightLightBtn">
                      <li className="flow-root">
                        <Link href="/report-store.php" className="block py-2 text-white">
                          Report Store
                        </Link>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="border-t">
                    <ul role="list" className="flex flex-col px-4 mt-3 space-y-3 hightLightBtn1">
                      <li className="flow-root">
                        <Link href="/request-for-proposal" className="block py-2 text-white">
                          Get Proposal
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Tab.Panels>
              </Tab.Group>

              <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                {MobileCategories.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <Link href={page.link} className="block p-2 -m-2 text-sm text-gray-900">
                      {page.name}
                    </Link>
                  </div>
                ))}
              </div>

              <div className='grid grid-cols-1 border-t'>
                <>
                  {(auth) ?
                    <>
                      <Link
                        key={"Logout"}
                        href="/logout"
                        className="flex items-center px-4 py-4 border-b hover:bg-slate-50">
                        <LockOpenIcon
                          className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                          aria-hidden="true"
                        />
                        <div className="ml-4">
                          <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                            Logout
                          </p>
                        </div>
                      </Link>
                      <Link
                        key={"Dashboard"}
                        href="/dashboard"
                        className="flex items-center px-4 py-4 text-sm hover:bg-slate-50">
                        {(auth) ?
                          <div className="flex-shrink-0 h-6 w-6 text-[#1b5df3]">
                            <Image priority={false}
                              width={2500}
                              height={2500}
                              className="w-6 h-6 m-0 rounded-full"
                              alt={user.name}
                              title={user.name}
                              src={user.picture}
                            />
                          </div>
                          :
                          <UserCircleIcon
                            className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                            aria-hidden="true"
                            title="Profile"
                          />
                        }
                        <div className="ml-4">
                          <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                            Dashboard
                          </p>
                        </div>
                      </Link>
                    </>
                    :
                    <Link
                      key={"Login"}
                      href="/login"
                      title="Login"
                      className="flex items-center px-4 py-4 text-sm hover:bg-slate-50">
                      <LockClosedIcon
                        className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                        aria-hidden="true"
                        title="Login"
                      />
                      <div className="ml-4">
                        <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                          Login
                        </p>
                      </div>
                    </Link>
                  }
                </>
              </div>
              {/* <div className='grid grid-cols-1 py-3 border-t'>
                <>
                  <Link
                    key={"Cart"}
                    href="/cart"
                    aria-label="Cart"
                    className="relative flex items-center px-4 py-4 text-sm hover:bg-slate-50">
                    <ShoppingCartIcon
                      className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                      aria-hidden="true"
                      aria-label="Cart"
                    />
                    <div className="ml-4">
                      <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                        Cart
                      </p>
                    </div>
                    <div className="absolute top-0 inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full left-8 dark:border-gray-900">
                      {cartValue?.length}
                    </div>
                  </Link>
                </>
              </div> */}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative text-black bg-white border-0 shadow-sm dark:bg-gray-800 dark:text-white focus-visible:outline-none">
        <nav aria-label="Top" className="flex justify-between w-11/12 mx-auto text-black border-b xl:max-w-screen max-w-screen-2xl focus-visible:outline-none border-b-white dark:border-b-gray-800 dark:bg-gray-800 dark:text-white">
          <div className="flex items-center justify-between w-full h-16 focus-visible:outline-none">
            <div className="flex items-center">
              <button
                type="button"
                className="text-black rounded focus-visible:outline-none dark:text-white lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="w-6 h-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="flex lg:ml-0 focus-visible:outline-none">
                <Link href="/" className="h-full">
                  <span className="sr-only">Delveinsight</span>
                  <Image priority={true}
                    width={2500}
                    height={2500}
                    className="w-32 h-auto ml-2 lg:w-36 xl:full lg:ml-0"
                    src={process.env.IMG_CDN + 'png/delve-logo.png'}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>

            {/* Flyout menus */}
            <Popover.Group className="hidden w-full lg:ml-0 lg:block lg:self-stretch focus-visible:outline-none">
              <div className="flex justify-end w-full h-full pr-6 space-x-2 xl:space-x-4 2xl:space-x-6 focus-visible:outline-none">
                {Categories.AboutUs.map((category) => (
                  <Popover key={category.name + "P1"} className="flex focus-visible:outline-none"
                    onMouseEnter={() => setIsHoveredAbout(true)}
                    onMouseLeave={() => setIsHoveredAbout(false)}>
                    {({ open }) => (
                      <>
                        <div className="relative flex focus-visible:outline-none">
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                                : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                              'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                            )}
                          >
                            {category.name}<ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          show={isHoveredAbout || open}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Popover.Panel className="lg:w-[250px] bg-white rounded-b shadow-lg absolute top-full text-sm  dark:bg-gray-800 text-black dark:text-gray-400 transform ">
                            <div className="grid grid-cols-12 gap-0">
                              {category.sections.map((about, index) => (
                                <div key={index + "_cats"} className="col-span-12">
                                  <ul
                                    role="list"
                                    className="">
                                    {about.items.map((item) => (
                                      <Link
                                        key={item.name + "_cats"}
                                        href={process.env.APP_URL + item.link}
                                        className="flex items-center px-4 py-4 border-b hover:bg-slate-50">
                                        <item.icon
                                          className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                          aria-hidden="true"
                                        />
                                        <div className="ml-4">
                                          <p className="m-0 text-black dark:bg-gray-800 dark:text-white">
                                            {item.name}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}
                {Categories.Consulting.map((category) => (
                  <Popover key={category.name + "P2"} className="flex focus-visible:outline-none"
                    onMouseEnter={() => setIsHoveredConsulting(true)}
                    onMouseLeave={() => setIsHoveredConsulting(false)}>
                    {({ open }) => (
                      <>
                        <div className="relative flex focus-visible:outline-none">
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                                : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                              'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                            )}
                          >
                            <Link href="/consulting" className='relative flex items-center whitespace-nowrap'>
                              {category.name}<ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                            </Link>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          show={isHoveredConsulting || open}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel className="lg:w-[1024px] bg-white mx-auto rounded-b shadow-lg absolute top-full inset-x-0 text-sm  dark:bg-gray-800 text-black dark:text-gray-400 transform ">
                            <div className="grid grid-cols-12 p-4">
                              {category.sections.map((consulting, index) => (
                                <div key={index + "_cats1"} className="col-span-4">
                                  <ul role="list" className="">
                                    {consulting.items.map((item) => (
                                      <Link key={item.name + "_cats2"} href={process.env.APP_URL + item.link} className="hover:text-gray-800">
                                        <li className="group flex w-full items-center gap-x-4 cursor-pointer hover:bg-[#1b5df3] hover:text-white p-3 rounded-lg">
                                          <Image priority={false}
                                            className="block w-6 h-6 group-hover:hidden"
                                            width={2500}
                                            height={2500}
                                            src={process.env.IMG_CDN + item.imageIconBlue}
                                            alt={item.altText ? item.altText : 'DelveInsight'}
                                          />
                                          <Image priority={false}
                                            className="hidden w-6 h-6 group-hover:block"
                                            width={2500}
                                            height={2500}
                                            src={process.env.IMG_CDN + item.imageIconWhite}
                                            alt={item.altText ? item.altText : 'DelveInsight'}
                                          />
                                          {item.name}
                                        </li>
                                      </Link>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                              <div className="col-span-4">
                                <div className="relative pb-2 pl-4 ml-4 border-l">
                                  {category.featured.map((item) => (
                                    <div key={item.name + "_cats3"} className="w-full h-40 mb-4 rounded-lg bg-[#1b5df3] overflow-hidden relative">
                                      <Image priority={false}
                                        width={2500}
                                        height={2500}
                                        src={process.env.IMG_CDN + item.imageSrc}
                                        alt={item.altText ? item.altText : 'DelveInsight'}
                                        className="absolute bottom-0 right-0 w-auto h-auto"
                                      />
                                      <div className="absolute inset-0 bg-[#00000026] opacity-90"></div>
                                      <Link href={process.env.APP_URL + item.link}>
                                        <h3 className="absolute w-full px-4 py-2 text-lg text-white">{item.name}</h3>
                                      </Link>
                                      <p className="absolute w-8/12 p-4 my-0 text-base text-white top-10 ">{item.shortDescription}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}
                {Categories.Capabilities.map((category) => (
                  <Popover
                    key={category.name + "P3"}
                    className="flex focus-visible:outline-none"
                    onMouseEnter={() => setIsHoveredCapabilities(true)}
                    onMouseLeave={() => setIsHoveredCapabilities(false)}>
                    {({ open }) => (
                      <>
                        <div className="relative flex focus-visible:outline-none ">
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                                : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                              'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                            )}
                          >
                            {category.name}<ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          show={isHoveredCapabilities || open}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Popover.Panel className="lg:w-[1024px] bg-white mx-auto rounded-b shadow-lg absolute top-full inset-x-0 text-sm  dark:bg-gray-800 text-black dark:text-gray-400 transform ">
                            <div className="grid grid-cols-12 p-4">
                              {category.sections.map((capabilities, index) => (
                                <div key={index + "_cats4"} className="col-span-4">
                                  {capabilities.items.map((item, index) => (
                                    <div key={index + "_cats5"} className="w-full mb-2 flex justify-between gap-x-4 hover:bg-[#40576d12] p-3 rounded-lg">
                                      <div className="h-auto w-28">
                                        <Link href={process.env.APP_URL + item.link}>
                                          <Image priority={true}
                                            alt={item.altText ? item.altText : 'DelveInsight'}
                                            width={1500}
                                            height={1500}
                                            quality={100}
                                            className="w-full h-full rounded-lg cursor-pointer"
                                            src={process.env.IMG_CDN + item.imageSrc}
                                          />
                                        </Link>
                                      </div>
                                      <div className="w-full">
                                        <Link href={process.env.APP_URL + item.link}>
                                          <h3 className="text-lg font-semibold cursor-pointer whitespace-nowrap">{item.name}</h3>
                                        </Link>
                                        <p className="m-0">{item.shortDescription}</p>
                                      </div>
                                    </div>

                                  ))}
                                </div>
                              ))}
                              {category.featured.map((item, index) => (
                                <div key={index + "_cats6"} className="col-span-4">
                                  <div className="relative pb-5 pl-4 ml-4 border-l">
                                    <div className="w-full overflow-hidden rounded-lg">

                                      <Link href={process.env.APP_URL + item.link}>
                                        <Image priority={false}
                                          alt={item.altText ? item.altText : 'DelveInsight'}
                                          width={1500}
                                          height={1500}
                                          quality={100}
                                          className="object-cover w-full transition duration-500 cursor-pointer h-52 hover:scale-110"
                                          src={process.env.IMG_CDN + item.imageSrc}
                                        />
                                      </Link>
                                    </div>
                                    <div className="w-full my-4">
                                      <Link href={process.env.APP_URL + item.link}>
                                        <h3 className="text-lg font-semibold cursor-pointer whitespace-nowrap">{item.name}</h3>
                                      </Link>
                                      <p className="m-0">{item.shortDescription}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="col-span-12 border-t">
                                <div className="p-4 pt-3 pb-0">
                                  <Link href="/request-for-proposal">
                                    <h3 className="text-lg font-semibold">Request for Proposal <i className="fa fa-angle-right"></i></h3>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}
                {Categories.Insights.map((category) => (
                  <Popover key={category.name + "P4"} className="flex focus-visible:outline-none"
                    onMouseEnter={() => setIsHoveredResource(true)}
                    onMouseLeave={() => setIsHoveredResource(false)}>
                    {({ open }) => (
                      <>
                        <div className="relative flex focus-visible:outline-none">
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                                : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                              'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                            )}
                          >
                            {category.name}<ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          show={isHoveredResource || open}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Popover.Panel className="lg:w-[1024px] bg-white mx-auto rounded-b shadow-lg absolute top-full inset-x-0 text-sm  dark:bg-gray-800 text-black dark:text-gray-400 transform ">
                            <div className="grid grid-cols-12 p-4">
                              <>
                                <div className="col-span-8">
                                  <div className="grid grid-cols-12 gap-4">
                                    {category.featured.map((item, index) => (
                                      <div className="col-span-4" key={index + "_cats7"}>
                                        <div className="pb-2">
                                          <div className="w-full overflow-hidden rounded-lg">
                                            {
                                              (item.name == 'Blog') ?
                                                <a href={process.env.APP_URL + item.link}>
                                                  <Image priority={false}
                                                    alt={item.altText ? item.altText : 'DelveInsight'}
                                                    width={1500}
                                                    height={1500}
                                                    quality={100}
                                                    className="object-cover w-full h-40 transition duration-500 cursor-pointer hover:scale-110"
                                                    src={process.env.IMG_CDN + item.imageSrc}
                                                  />
                                                </a>
                                                :
                                                <Link href={process.env.APP_URL + item.link}>
                                                  <Image priority={false}
                                                    alt={item.altText ? item.altText : 'DelveInsight'}
                                                    width={1500}
                                                    height={1500}
                                                    quality={100}
                                                    className="object-cover w-full h-40 transition duration-500 cursor-pointer hover:scale-110"
                                                    src={process.env.IMG_CDN + item.imageSrc}
                                                  />
                                                </Link>
                                            }

                                          </div>
                                          <div className="w-full my-4">
                                            {
                                              (item.name == 'Blog') ?
                                                <a href={process.env.APP_URL + item.link}>
                                                  <h3 className="text-lg font-semibold cursor-pointer whitespace-nowrap">{item.name} </h3>
                                                </a>
                                                :
                                                <Link href={process.env.APP_URL + item.link}>
                                                  <h3 className="text-lg font-semibold cursor-pointer whitespace-nowrap">{item.name} </h3>
                                                </Link>
                                            }
                                            <p className="m-0">{item.shortDescription}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                              {category.sections.map((insights, index) => (
                                <div key={index + "_cats8"} className="col-span-4 pl-4 ml-4 border-l">
                                  {insights.items.map((item, index) => (
                                    <div key={index + "PPLIST"} className="w-full mb-2 flex justify-between gap-x-4 hover:bg-[#40576d12] p-3 rounded-lg">
                                      <div className="h-20 w-28">
                                        <Link href={process.env.APP_URL + item.link}>
                                          <Image priority={false}
                                            alt={item.altText ? item.altText : 'DelveInsight'}
                                            width={1500}
                                            height={1500}
                                            quality={100}
                                            className="w-full h-full rounded-lg cursor-pointer"
                                            src={process.env.IMG_CDN + item.imageSrc}
                                          />
                                        </Link>
                                      </div>
                                      <div className="w-full">
                                        <Link href={process.env.APP_URL + item.link}>
                                          <h3 className="text-lg font-semibold cursor-pointer whitespace-nowrap">{item.name}</h3>
                                        </Link>
                                        <p className="m-0">{item.shortDescription}</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                              <div className="col-span-12 border-t">
                                <div className="p-4 pb-0">
                                  <Link href="/blog/analysis-and-perspectives">
                                    <h3 className="text-lg font-semibold">Analysis & Perspective <i className="fa fa-angle-right"></i></h3>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}
                {Categories.TherapyArea.map((category) => (
                  <Popover key={category.name + "P5"} className="flex focus-visible:outline-none"
                    onMouseEnter={() => setIsHoveredTherapyAera(true)}
                    onMouseLeave={() => setIsHoveredTherapyAera(false)}>
                    {({ open }) => (
                      <>
                        <div className="relative flex focus-visible:outline-none">
                          <Popover.Button
                            className={classNames(
                              open
                                ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                                : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                              'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                            )}
                          >
                            {category.name}<ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          show={isHoveredTherapyAera || open}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Popover.Panel className="lg:w-[1024px] bg-white mx-auto rounded-b shadow-lg absolute top-full inset-x-0 text-sm  dark:bg-gray-800 text-black dark:text-gray-400 transform ">
                            <div className="grid grid-cols-12 gap-4 p-4">
                              <div className="col-span-4 pr-4 border-r">
                                <div className="grid grid-cols-12 gap-4 pb-2">
                                  <div className="col-span-12">
                                    <p className="text-base bg-[#40576d12] p-2 px-4 my-1 mb-3 rounded-lg">Trending Therapeutic Areas</p>
                                  </div>
                                </div>
                                <div className="grid grid-cols-12 gap-4 pb-2">
                                  <>
                                    {category.featured.map((item, index) => (
                                      <div key={index + "_cats9"} className="col-span-6">
                                        <div className="relative flex items-center rounded-lg">
                                          <div className="relative flex items-center w-full overflow-hidden rounded-lg hover:rounded-lg">
                                            <Link href={process.env.APP_URL + item.link} className="relative w-full overflow-hidden first-line:rounded-lg hover:rounded-lg">
                                              <Image priority={false}
                                                alt={item.altText ? item.altText : 'DelveInsight'}
                                                width={2500}
                                                height={2500}
                                                className="object-cover w-full h-24 transition duration-500 rounded-lg cursor-pointer hover:rounded-lg hover:scale-110"
                                                src={process.env.IMG_CDN + item.imageSrc}
                                              />
                                              <p className="absolute flex items-center justify-center w-full text-white top-6">{item.name}</p>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                </div>
                              </div>
                              <div className="col-span-8">
                                <p className="text-base bg-[#40576d12] p-2 px-4 my-1 rounded-lg">Our expertise in the industry</p>
                                <div className="grid grid-cols-12">
                                  <>
                                    {category.sections.map((therapyArea, index) => (
                                      <div key={index + "_cats10"} className="col-span-4">
                                        <ul
                                          role="list"
                                          className=""
                                        >
                                          {therapyArea.items.map((item) => (
                                            <Link key={item.name + "_cats11"} href={process.env.APP_URL + item.link} className="hover:text-gray-800">
                                              <li className="flex w-full justify-between gap-x-4 cursor-pointer hover:bg-[#40576d12] p-3 rounded-lg">
                                                {item.name}
                                              </li>
                                            </Link>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                ))}

                <Link
                  href="/report-store.php"
                  className="flex items-center text-black focus-visible:outline-none whitespace-nowrap dark:bg-gray-800 dark:text-white hover:text-gray-900"
                >
                  <span className="bg-[#1b5df3] md:text-xs lg:text-sm xl:text-base text-white py-1 px-4 rounded hightLightBtn">Report Store</span>
                </Link>

                <Link
                  href="/request-for-proposal"
                  className="flex items-center text-black focus-visible:outline-none whitespace-nowrap dark:bg-gray-800 dark:text-white hover:text-gray-900"
                >
                  <span className="bg-[#f53232] md:text-xs lg:text-sm xl:text-base text-white py-1 px-4 rounded hightLightBtn1">Get Proposal</span>
                </Link>

                <Popover className="flex focus-visible:outline-none">
                  {({ open }) => (
                    <>
                      <div className="relative flex focus-visible:outline-none">
                        <Popover.Button
                          aria-label="Profile"
                          className={classNames(
                            open
                              ? 'border-[#1b5df3] text-[#1b5df3] focus-visible:outline-none'
                              : 'border-transparent  dark:bg-gray-800 text-black dark:text-white focus-visible:outline-none hover:text-gray-800',
                            'whitespace-nowrap relative flex items-center transition-colors ease-out duration-200 lg:text-sm xl:text-base font-semibold border-b-2'
                          )}
                        >
                          {(auth) ?
                            <div className="flex items-center w-6 h-full">
                              {(user?.avatar) ? <><Image priority={true}
                                width={2500}
                                height={2500}
                                className="w-6 h-6 m-0 rounded-full"
                                alt={user.name}
                                title={user.name}
                                src={process.env.IMG_CDN + user?.avatar}
                              /></> : <UserCircleIcon
                                className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                aria-hidden="true"

                              />}

                            </div>
                            :
                            <UserCircleIcon
                              className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                              aria-hidden="true"

                            />
                          }
                          <ChevronDownIcon className="w-3 h-3" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Popover.Panel className="absolute inset-x-0 w-2/12 text-sm text-black transform bg-white rounded-b shadow-lg top-full dark:bg-gray-800 dark:text-gray-400 sm:px-0 lg:ml-0 lg:left-3/4 lg:-translate-x-0">
                          <div className="grid grid-cols-12 gap-0">
                            <div className="col-span-12">
                              <ul role="list" className="">
                                <>
                                  {(auth) ?
                                    <>
                                      <Link
                                        key={"Logout"}
                                        href="/logout"
                                        className="flex items-center px-4 py-4 border-b hover:bg-slate-50">
                                        <LockOpenIcon
                                          className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                          aria-hidden="true"
                                          aria-label="Logout"
                                        />
                                        <div className="ml-4">
                                          <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                                            Logout
                                          </p>
                                        </div>
                                      </Link>
                                      <Link
                                        key={"Dashboard"}
                                        href="/dashboard"
                                        aria-label="Profile"
                                        className="flex items-center px-4 py-4 text-sm border-b hover:bg-slate-50">
                                        {(auth) ?
                                          <div className="flex-shrink-0 h-6 w-6 text-[#1b5df3]">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                                            </svg>

                                          </div>
                                          :
                                          <UserCircleIcon
                                            className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                            aria-hidden="true"
                                            title="Profile"
                                          />
                                        }
                                        <div className="ml-4">
                                          <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                                            Dashboard
                                          </p>
                                        </div>
                                      </Link>
                                    </>
                                    :
                                    <Link
                                      key={"Login"}
                                      href="/login"
                                      className="flex items-center px-4 py-4 text-sm border-b hover:bg-slate-50">
                                      <LockClosedIcon
                                        className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                        aria-hidden="true"
                                        aria-label="Login"
                                      />
                                      <div className="ml-4">
                                        <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                                          Login
                                        </p>
                                      </div>
                                    </Link>
                                  }
                                </>
                                <Link
                                  key={"Cart"}
                                  href="/cart"
                                  title="Cart"
                                  className="relative items-center hidden px-4 py-4 lg:flex xl:hidden">
                                  <ShoppingCartIcon
                                    aria-label="Cart"
                                    className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4">
                                    <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                                      Cart
                                    </p>
                                  </div>
                                  <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full top-2 left-8 dark:border-gray-900">
                                    {cartValue?.length}
                                  </div>
                                </Link>
                              </ul>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <div className='relative items-center py-4 lg:hidden xl:flex'>
                  <Link
                    key={"Cart"}
                    href="/cart"
                    title="Cart"
                    className="">
                    <ShoppingCartIcon
                      aria-label="Cart"
                      className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                      aria-hidden="true"
                    />

                    {/* <div className="ml-4">
                      <p className="flex m-0 text-sm text-black dark:bg-gray-800 dark:text-white">
                        Cart 
                      </p>
                    </div> */}
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full top-2 left-4 dark:border-gray-900">
                      {cartValue?.length}
                    </div>
                  </Link>
                </div>
              </div>
            </Popover.Group>

            <div className="flex items-center justify-end focus-visible:outline-none">
              <div className="hidden lg:flex lg:items-center lg:justify-end focus-visible:outline-none">
                <span className="w-px h-6 bg-gray-200" aria-hidden="true" />
              </div>
              {/* Search */}
              <div className="flex cursor-pointer focus-visible:outline-none">
                <div className="relative flex items-center p-0 text-black navSerach dark:bg-gray-800 dark:text-white hover:text-gray-500 focus-visible:outline-none">
                  <span className="sr-only">Search</span>
                  <div className='flex items-center p-4 lg:hidden xl:hidden'>
                    <Link
                      key={"Cart"}
                      href="/cart"
                      title="Cart"
                      className="">
                      <ShoppingCartIcon
                        aria-label="Cart"
                        className="flex-shrink-0 h-6 w-6 text-[#1b5df3]"
                        aria-hidden="true"
                      />
                      <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full top-2 left-8 dark:border-gray-900">
                        {cartValue?.length}
                      </div>
                    </Link>
                  </div>
                  <div>
                  {(searchIcon) ?
                    <AutocompleteWithPreview />
                    :
                    <>
                    <button type="button" className="aa-DetachedSearchButton" title="Search" id="autocomplete-12-label"><div className="aa-DetachedSearchButtonIcon"><svg className="aa-SubmitIcon" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path></svg></div><div className="aa-DetachedSearchButtonPlaceholder">What are you looking for?</div><div className="aa-DetachedSearchButtonQuery"></div></button>
                    </>
                  }
                  </div>

                </div>
                
              </div>
              
              
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

